import { getUserById } from '../../services/account.service';
import {
  getCandidatesForOffer,
  getJobOfferById,
  getJobOffersById,
} from '../../services/search.service';

export default {
  state: {
    listOfJobOffers: [],
    listOfCandidatesForThisJobOffer: [],
    selectedJobOffer: null,
    selectedCandidateForThisJobOffer: null,
  },
  mutations: {
    setListOfJobOffers(state, newListOfJobOffers) {
      state.listOfJobOffers = newListOfJobOffers;
    },
    setSelectedJobOffer(state, newSelectedJobOffer) {
      state.selectedJobOffer = newSelectedJobOffer;
    },
    setListOfCandidatesForThisJobOffer(state, newListOfCandidates) {
      state.listOfCandidatesForThisJobOffer = newListOfJobOffers;
    },
    setSelectedCandidateForThisJobOffer(state, newSelectedCandidate) {
      state.selectedCandidateForThisJobOffer = newSelectedCandidate;
    },
  },
  actions: {
    async fetchJobOffers({ state, commit }, { offerId, favoriteIds }) {
      const offersInStore = state.listOfJobOffers;
      const job = offersInStore.find((job) => job.id === parseInt(offerId));

      if (job) {
        return {
          jobOffer: job,
          candidatesList: job.postulants.map((postulant) => ({
            ...postulant,
            candidateIsLiked: favoriteIds.has(postulant.user_id),
          })),
        };
      } else {
        try {
          const jobOffer = await getJobOfferById(offerId);

          if (!jobOffer) {
            throw new Error('JobOfferNotFound');
          }

          const { postulants } = await getCandidatesForOffer(offerId);
          return {
            jobOffer,
            candidatesList: postulants.map((postulant) => ({
              ...postulant,
              candidateIsLiked: favoriteIds.has(postulant.user_id),
            })),
          };
        } catch (error) {
          console.error(
            'Erreur lors de la récupération des données:',
            error.message
          );
          throw error;
        }
      }
    },
    async fetchCandidatureData({ state, commit }, { offerId, candidateId }) {
      const jobOfferInStore = state.listOfJobOffers.find(
        (job) => job.id === parseInt(offerId)
      );

      if (jobOfferInStore) {
        const basicCandidateData = jobOfferInStore.postulants.find(
          (candidate) => candidate.user_id === parseInt(candidateId)
        );

        if (!basicCandidateData) {
          throw new Error('CandidateNotFoundInJobOffer');
        }

        if (
          !state.selectedCandidateForThisJobOffer ||
          state.selectedCandidateForThisJobOffer.id !== parseInt(candidateId)
        ) {
          try {
            const fullCandidateData = await getUserById(candidateId);
            commit('setSelectedCandidateForThisJobOffer', fullCandidateData);
          } catch (error) {
            console.error(
              'Error al recuperar los datos completos del candidato:',
              error
            );
            throw error;
          }
        }

        return {
          jobOffer: jobOfferInStore,
          candidate: state.selectedCandidateForThisJobOffer,
        };
      } else {
        try {
          const jobOffer = await getJobOffersById(offerId);

          const candidate = await getUserById(candidateId);
          commit('setSelectedCandidateForThisJobOffer', candidate);

          return {
            jobOffer,
            candidate,
          };
        } catch (error) {
          console.error('Erreur lors de la récupération des données:', error);
          throw error;
        }
      }
    },
  },
  getters: {
    getListOfJobOffers: (state) => state.listOfJobOffers,
    getListOfCandidatesForThisJobOffer: (state) =>
      state.listOfCandidatesForThisJobOffer,
    getSelectedCandidateForThisJobOffer: (state) =>
      state.selectedCandidateForThisJobOffer,
  },
};
