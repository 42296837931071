<template>
  <Navbar v-if="showComponent" @view-switched="handleViewSwitch" />
  <div class="loader-container" v-if="!isReady">
    <v-progress-circular
      class="progress-circular"
      indeterminate
    ></v-progress-circular>
  </div>
  <router-view
    class="router-view"
    v-if="isReady"
    :user="getUser"
    :is-viewing-as-recruiter="isViewingAsRecruiter"
    :key="$route.fullPath"
  />
  <!--  --><MessagerieLayout v-if="$store.state.isLoggedIn" />
  <Footer v-if="showComponent" />
</template>

<script>
  import Footer from '@/components/layout/footer/Footer.vue';
  import MessagerieLayout from '@/components/layout/messagerie/MessagerieLayout.vue';
  import Navbar from '@/components/layout/navbar/Navbar.vue';
  import { addUserToStore } from '@/services/account.service.js';
  import { mapGetters } from 'vuex';
  import { mapActions } from 'vuex/dist/vuex.cjs.js';
  import { checkAuthStatus } from './services/account.service';

  export default {
    components: {
      Navbar,
      Footer,
      MessagerieLayout,
    },
    name: 'App',
    data() {
      return {
        isUserLogin: false,
        isReady: false,
        actualRoute: '',
        isViewingAsRecruiter: false,
      };
    },
    watch: {
      $route(to, from) {
        this.actualRoute = to.path;
      },
    },
    computed: {
      ...mapGetters(['getUser']),
      showComponent() {
        return this.checkRoute();
      },
    },
    methods: {
      ...mapActions(['initializeUserConnectionWebSocket']),
      checkRoute() {
        // routes where navbar and footer should not be displayed
        switch (this.actualRoute) {
          case '/register':
            return false;
          case '/login':
            return false;
          case '/password-reset':
            return false;
          case '/password-reset-confirm':
            return false;
          case '/recruiter/purchase/recap':
            return false;
          case '/achat':
            return false;
          case '/purchase/info':
            return false;
          case '/purchase/devis':
            return false;
          case '/purchase/paiement':
            return false;
          case '/recruiter/purchase/recap':
            return false;
          case '/recruiter/purchase/info':
            return false;
          case '/recruiter/purchase/devis':
            return false;
          case '/recruiter/purchase/paiement':
            return false;
          case '/recruiter/login':
            return false;
          case '/recruiter/register':
            return false;
          default:
            return true;
        }
      },
      handleViewSwitch(value) {
        this.isViewingAsRecruiter = value;
        console.log('View switched to:', value ? 'recruiter' : 'candidate');
      },
    },
    async mounted() {
      try {
        this.actualRoute = this.$route.path;
        // check if the route need to fetch user data
        if (this.checkRoute()) {
          // check if user is logged in and the token is valid
          const isLoggedIn = await checkAuthStatus();
          if (isLoggedIn) {
            await addUserToStore();
            this.isUserLogin = true;
          }
        }
        // Initialize the user connection web socket
        // Ce websocket est utilisé pour écouter les notifications de l'utilisateur et pour definir son état de connection
        this.initializeUserConnectionWebSocket();
      } catch (error) {
        console.log({ 'error from app.vue in mounted': error });
      } finally {
        this.isReady = true;
      }
    },
  };
</script>
<style>
  :root {
    /* colors variables, couleurs variables */
    --white-100: #f5f2ef;
    --white-200: #fffdfc;
    --white-10: hsl(20, 100%, 99%, 0.1);

    --black-100: #26282b;
    --black-200: #232123;

    --gray-100: #626161;
    --gray-10: rgba(38, 40, 43, 10%);
    --gray-light: #3b3d40;
    --gray-light-opacity: rgba(59, 61, 64, 0.2);

    --yellow-100: #f6b337;
    --yellow-80: #f5c772;
    --yellow-20: rgba(246, 179, 55, 0.2);

    --brown-100: #504538;
    --brown-80: #554e46;
    --brown-60: #b69d7f;

    --beige-100: #b6a999;
    --beige-80: #cfc6ba;
    --beige-60: #dfdbd6;

    --turquoise-100: #00dec4;
    --turquoise-80: #8bdbd1;

    --blue-100: #00a58e;
    --blue-80: #58a096;
    --blue-20: rgba(89, 161, 153, 0.2);

    --pink: #efbec1;

    /* primary colors, couleurs primaires */
    --primary-1: var(--yellow-100);
    --primary-1b: var(--yellow-80);
    --primary-1b2: var(--yellow-20);
    --primary-2: var(--brown-100);
    --primary-2b: var(--brown-80);
    --primary-2b2: var(--brown-60);
    --primary-3: var(--beige-100);
    --primary-3b: var(--beige-80);
    --primary-3b2: var(--beige-60);
    --secondary-1: var(--turquoise-100);
    --secondary-1b: var(--turquoise-80);
    --secondary-2: var(--blue-100);
    --secondary-2b: var(--blue-80);
    --secondary-2b2: var(--blue-20);

    /* text / background colors, couleurs de texte / fond */
    --surface-bg: var(--white-100);
    --surface-bg-2: var(--white-200);
    --surface-bg-3: var(--white-10);
    --surface-bg-4: var(--gray-10);
    --surface-bg-5: var(--black-100);
    --text-1: var(--black-100);
    --text-2: var(--black-200);
    --text-3: var(--gray-100);

    /* navbar, barre de navigation */
    /* navbar colors, barre de navigation couleurs */
    --navbar-bg-color: var(--text-1);
    --navbar-font-color: var(--surface-bg-2);
    /* navbar hover colors, barre de navigation survol couleurs */
    --navbar-hover-bg-color: var(--primary-1);
    --navbar-hover-font-color: var(--surface-bg-2);
    /* navbar active colors, barre de navigation active couleurs */
    --navbar-active-bg-color: var(--primary-1);
    --navbar-active-font-color: var(--surface-bg-2);
    /* navbar font, barre de navigation police */
    --navbar-font-size: 14px;

    /* footer colors, pied de page couleurs */
    --footer-bg-color: var(--black-100);
    --footer-font-color: var(--white-200);
    --footer-font-size: 14px;
    --footer-font-family: 'Roboto', sans-serif;
    --footer-font-weight: 400;
    --footer-font-style: normal;

    /* primary rounded button, bouton rond primaire */
    --primary-rounded-btn-bg-color: var(--yellow-100);
    --secondary-rounded-btn-bg-color: var(--black-100);
    --light-rounded-btn-bg-color: var(--white-200);
    --primary-rounded-btn-color: var(--black-100);
    --secondary-rounded-btn-color: var(--white-200);
    --light-rounded-btn-color: var(--black-100);
    --light-rounded-btn-border-color: var(--yellow-100);
    --primary-rounded-btn-font-weight: 400;
    --primary-rounded-btn-font-size: 14px;
    --primary-rounded-btn-font-familly: 'Roboto', sans-serif;

    /* home page, page principale */
    --home-hero-section-bg-color: var(--surface-bg);
    --home-about-section-bg-color: var(--surface-bg-5);
    --home-how-section-bg-color: var(--surface-bg);
    --home-news-section-bg-color: var(--surface-bg-2);
    --home-sponsor-section-bg-color: var(--surface-bg);
    --home-search-section-bg-color: var(--surface-bg-2);
    --home-cards-bg-color: var(--surface-bg);
    --home-cards-bg-color-2: var(--surface-bg-2);
    --home-cards-number-bg-color: var(--yellow-80);
    --home-cards-number-font-familly: 'Anton', sans-serif;
    --home-searchdiv-bg-color: var(--yellow-100);

    /* candidates search page, page de recherche des candidats */
    --search-candidate-bg-color: var(--surface-bg);
    --search-candidate-searchfield-bg-color: var(--surface-bg-2);
    --search-candidate-searchbtn-bg-color: var(
      --secondary-rounded-btn-bg-color
    );
    --search-candidate-candidatecard-bg-color: var(--surface-bg-2);

    /* subscription, abonnement */
    --subscription-card-font-color: var(--black);
  }
  /* dark theme 
html[data-theme='dark'] {
  --text-color: var(--white);
}
*/
  #app,
  html,
  body,
  main {
    min-height: 100%;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color: var(--surface-bg);
    scroll-behavior: smooth;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .loader-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Le conteneur principal de les pages */
  main.container {
    min-height: 100vh;
    margin-top: 30px;
    margin-bottom: 80px;
  }

  .d-flex {
    display: flex;
  }

  .d-none {
    display: none;
  }

  .j-content {
    justify-content: center;
  }

  .a-center {
    align-items: center;
  }

  .flex-column {
    flex-direction: column;
  }

  .text-underline {
    text-decoration: underline;
  }

  /* padding classes */
  .padding-container {
    padding-inline: 3vw;
  }

  .border-radius-2 {
    border-radius: 2px !important;
  }

  .border-radius-5 {
    border-radius: 5px !important;
  }

  .border-radius-10 {
    border-radius: 10px !important;
  }

  .border-radius-15 {
    border-radius: 15px !important;
  }

  .border-radius-20 {
    border-radius: 20px !important;
  }

  /* fonts */
  /* fonts size classes */
  .fs-14 {
    font-size: 14px;
  }

  .fs-12 {
    font-size: 12px;
  }

  /* tags */
  h1 {
    font-size: 45px;
    font-family: 'Anton', sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  h2 {
    font-size: 35px;
    font-family: 'Anton', sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  h3 {
    font-size: 23px;
    font-family: 'Average Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  h4 {
    font-size: 19px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  h5 {
    font-size: 19px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-style: normal;
  }

  h6 {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-style: normal;
  }

  label {
    font-size: 19px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-style: normal;
  }

  p {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  input {
    padding: 4px;
  }

  input::placeholder {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-style: italic;
  }

  button {
    font-size: 14px !important;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }

  /* buttons / boutons */
  /* set all v-btn text to lower case / applique le texte de tous les v-btn en minuscule */
  .v-btn {
    text-transform: none !important;
    letter-spacing: inherit !important;
    font-size: 12px !important;
  }

  @media screen and (min-width: 992px) {
    .padding-container {
      padding-inline: 12vw;
    }
  }
</style>
