<template>
  <div class="messagerie-layout">
    <!-- Contenedor del chat -->
    <ChatWindow
      v-for="user in selectedChats"
      :key="user.id"
      :receiver="user"
      :isInLayout="true"
      :isOpen="true"
      @closeChatWindow="handleCloseChatWindow"
      @start-video-call="startVideoCall"
    />
    <ChatUserList
      ref="chatUserList"
      :isInLayout="true"
      @selectUser="handleOpenChats"
    />
  </div>
</template>

<script>
  import ChatUserList from '../../chat/ChatUsersList.vue';
  import ChatWindow from '../../chat/ChatWindow.vue';

  export default {
    name: 'MessagerieLayout',
    components: {
      ChatUserList,
      ChatWindow,
    },
    data() {
      return {
        selectedChats: [],
      };
    },
    methods: {
      startVideoCall(recipientId) {
        this.selectedChats = [];
        this.$refs.chatUserList.toggleChatOpen();
        this.$router.push({
          path: '/messagerie',
          query: { chat: recipientId, call: true },
        });
      },
      handleOpenChats(user) {
        if (!this.selectedChats.some((chat) => chat.id === user.id)) {
          this.selectedChats.push(user);
          if (this.selectedChats.length > 4) {
            this.selectedChats.shift();
          }
        }
      },
      handleCloseChatWindow(receiverId) {
        this.selectedChats = this.selectedChats.filter(
          (chat) => chat.id !== receiverId
        );
      },
    },
  };
</script>

<style scoped>
  .messagerie-layout {
    position: sticky;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
    max-height: 0px;
    padding-right: 2rem;
    z-index: 10;
  }
  @media (max-width: 1024px) {
    .messagerie-layout {
      display: none;
    }
  }
</style>
