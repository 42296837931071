import { isCandidateFavoriteById } from '@/services/favoriteProfil.service';
import store from '@/store';
import { decodeCredential, googleOneTap } from 'vue3-google-login';
import { toaster } from '../utils/toast/toast';

import {
  axiosInstance,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from './axios';

/**
Login user by api
@param {Object} data
@param {Boolean} rememberMe
@return {Promise}
@throws {Error}*/
const login = async (data, rememberMe) => {
  try {
    const response = await axiosInstance.post('/user/token/', data, {
      withCredentials: false,
    });

    // ⚠️ On n'utilise que le refresh token pour l'instant
    const accessToken = response.data.access;

    const refreshToken = response.data.refresh;
    // ⚠️ On utilise le refresh token en localStorage pour le moment
    // ⚠️ refresh token au lieu de access token pour éviter la déconnexion de l'utilisateur
    // ⚠️ Cette fonction doit recevoir le access_token mais pour l'instant on utilise le refresh_token
    setAccessToken(accessToken);
    // ⚠️ On stocke le refresh token dans le localStorage pour le moment
    setRefreshToken(refreshToken);

    // ⚠️ On stocke le refresh token dans le localStorage alors les cookies ne sont pas utilisés pour le moment
    // setRefreshToken(refreshToken);

    store.dispatch('fetchUser');
    return response;
  } catch (error) {
    throw new Error(error.response.data?.detail || 'Login failed');
  }
};
/**
 * Ask for a new access token using the refresh token
 * @returns {Promise} {newAccessToken: string}
 * @throws {Error}
 */
export const refreshAccessToken = async () => {
  try {
    console.log('access token was expired, trying to refresh it');
    const response = await axiosInstance.post('/user/refresh-token/', {
      refresh: getRefreshToken(),
    });
    setAccessToken(response.data.access);
    return { newAccessToken: response.data.access };
  } catch (error) {
    throw new Error(error.response.data?.detail || 'Refresh token failed');
  }
};

/**
 * Check if user access token exist and if it is valid
 * return true if the auth status is valid and false if it is not
 * @returns {boolean}
 * @throws {Error}
 */
const checkAuthStatus = async () => {
  try {
    const token = localStorage.getItem('access_token');
    if (!token) {
      return false;
    }

    // Decomposer le token pour le vérifier
    const tokenParts = token.split('.');
    if (tokenParts.length !== 3) {
      return false; // Le token n'a pas un format valide
    }

    const payload = JSON.parse(atob(tokenParts[1]));
    const expirationTime = payload.exp * 1000; // Convertir en millisecondes

    // si le access_token est expiré on essaye de le renouveler avec le refresh token
    if (Date.now() >= expirationTime) {
      const refreshToken = getRefreshToken();
      if (!refreshToken) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');

        // ⚠️ Il faudra nettoyer le cookie quand on aura un refresh token en les cookies
        /* document.cookie =
        'refresh_token=; path=/; secure; samesite=strict; max-age=0'; */
        return false;
      }
      await refreshAccessToken();
      return true;
    }
    return true;
  } catch (error) {
    console.error('Error checking auth status:', error);
    return false;
  }
};
/**
/**
Login user by google one tap
@returns {Promise}
@throws {Error}*/
const googleLogin = async () => {
  try {
    const response = await googleOneTap({
      clientId:
        '499109195466-3cgb1ucg8oon8ncdemjpveuqkv7n0i61.apps.googleusercontent.com',
      context: 'signin',
    });

    const regData = decodeCredential(response.credential);
    // try to login pass regular
    const loginResponse = await login({
      email: regData.email,
      password: regData.sub,
    });
    // get user profile, and store it in vuex
    addUserToStore();
    return;
  } catch (error) {
    throw new Error(error.response.data.detail || 'Login failed');
  }
};
/**
Register user
@param {Object} credentials
@returns {Promise}*/
const register = async (credentials) => {
  try {
    const formData = new FormData();

    // suppression des cookies sessionid qui peuvent être en conflit avec les cookies de l'application
    // la cookie sessionid est cree dans le backend Django lors de la connexion au administrateur
    if (document.cookie.includes('sessionid')) {
      document.cookie =
        'sessionid=; path=/; domain=paris.thanks-boss.com; expires=Thu, 01 Jan 1970 00:00:00 GMT';

      document.cookie =
        'sessionid=; path=/; domain=marseille.thanks-boss.com; expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
    for (const key in credentials) {
      formData.append(key, credentials[key]);
    }

    const response = await axiosInstance.post('/user/register/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: false,
    });
    return response;
  } catch (error) {
    const errorData = error.response && error.response.data;
    let message = 'Erreur de connexion au serveur';

    if (errorData) {
      if (errorData.email) {
        message = errorData.email.join(', ');
      } else if (errorData.detail) {
        message = errorData.detail;
      } else {
        message = "Erreur lors de l\'inscription";
      }
    }
    if (error.response && error.response.status === 403) {
      message =
        'Nous vous prions de désactiver les bloqueurs de publicité pour créer un compte.';
    }

    throw new Error(message);
  }
};
/**
 * Logout user
 */
const logout = () => {
  try {
    axiosInstance.post('/user/log-out/');
  } catch (error) {
    console.error('Error during logout:', error);
  } finally {
    store.dispatch('logout');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('isLoggedIn');
    // ⚠️ Il faudra nettoyer le cookie quand on aura un refresh token en les cookies
    /* document.cookie =
      'refresh_token=; path=/; secure; samesite=strict; max-age=0'; */
    window.location.href = '/';
  }
};

const deleteAccount = async () => {
  try {
    toaster.showInfoPopup(
      'Votre compte est en cours de suppression. Vous allez être redirigé vers la page de connexion.'
    );

    await axiosInstance.delete('/user/');

    window.location.href = '/';

    return;
  } catch (error) {
    toaster.showErrorPopup();
    console.error('Error deleting user account:', error);
  }
};

// à deplacer dans un fichier user.js
const getUser = async () => {
  try {
    const response = await axiosInstance.get('/user/');
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.detail || 'User not found');
  }
};
const getUserById = async (id) => {
  try {
    const response = await axiosInstance.get(`/user/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.detail || 'User not found');
  }
};
const getUsersByIds = async (userIds) => {
  if (!userIds.length) return []; // Si le tableau est vide, retourner un tableau vide

  try {
    const userRequests = userIds.map((id) =>
      axiosInstance.get(`/user/${id}`).catch((error) => ({ error }))
    ); // Capturez les erreurs individuellement
    const usersResponses = await Promise.all(userRequests);

    return usersResponses
      .map((response) => (response.error ? null : response.data))
      .filter((user) => user); // Ignorez les réponses nulles
  } catch (error) {
    console.error('Erreur lors de la récupération des utilisateurs : ', error);
    throw error; // Propager l'erreur
  }
};

const addUserToStore = async () => {
  try {
    // Verify if user is already in store
    const user = store.getters['getUser'];
    if (user && user.id && user.email) {
      return;
    }

    // If user is not in store, get it and save it
    // ⚠️ getUser directement dans le store. pour éviter un await ici
    let userData = await getUser();
    if (!userData || !userData.type_user) {
      return;
    }
    if (userData.type_user === 'recruiter') {
      const recruteurFavorites = await isCandidateFavoriteById();
      userData = { ...userData, recruteurFavorites };
    }
    store.dispatch('handleUserChange', { type: null, payload: userData });
    store.dispatch('handleUserRoleChange', userData.type_user);
  } catch (error) {
    console.error('Error adding user to store:', error);
  }
};

export {
  checkAuthStatus,
  addUserToStore,
  deleteAccount,
  getUser,
  getUserById,
  getUsersByIds,
  googleLogin,
  login,
  logout,
  register,
};
