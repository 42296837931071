import router from '@/router/index.js';
import Vue from 'vue';
/**
 * gotoPage
 * @param {string} page
 * @param {object} target
 * @param {object} datas
 * @return {void}
 */
const gotoPage = (page, target, datas) => {
  console.log('gotoPage appelé avec : ', { page, target, datas }); // Log des paramètres d'entrée

  // Si target = "FT", ouvrir l'URL dans un nouvel onglet
  if (target === 'FT') {
    window.open(page, '_blank');
    return;
  }

  // Retourner une page en arrière
  if (page === '-1') {
    router.go(-1);
    return;
  }

  // Vérifier la présence de données dans target

  if (target && Object.keys(target).length > 0) {
    router
      .push({ path: page, query: target })
      .then(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .catch((err) =>
        console.error('Erreur lors de la navigation avec target :', err)
      );
  } else {
    router
      .push(page)
      .then(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .catch((err) =>
        console.error('Erreur lors de la navigation sans données :', err)
      );
  }
};

export default gotoPage;
