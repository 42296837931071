<template>
  <section class="chat-window">
    <!-- Chat Header -->
    <header class="chat-header">
      <div class="chat-header-info">
        <UserAvatar width="50" :user="receiver" />
        <p class="receiver-name">
          {{ receiver?.first_name || 'Sélectionner un destinataire' }}
        </p>
      </div>
      <div class="chat-header-actions">
        <button
          @click="startVideoCall"
          class="chat-action"
          aria-label="Start Video Call"
        >
          <img
            src="@/assets/viseo.svg"
            alt="Video Call Icon"
            @click="start - video - call"
          />
        </button>
        <button class="chat-action" aria-label="Start Call">
          <img src="@/assets/call.svg" alt="Call Icon" />
        </button>
        <button @click="onClick" class="chat-action" aria-label="Active Call">
          <img src="@/assets/icons/eye-border-orange.svg" alt="Eye Icon" />
        </button>
        <button class="chat-action" aria-label="More Options">
          <img src="@/assets/dots-black.svg" alt="More Options Icon" />
        </button>
      </div>
      <button
        v-if="isInLayout"
        class="toggle-chat"
        aria-label="Toggle Chat Visibility"
      >
        <i
          @click="toggleChatOpen"
          :class="
            isChatWindowOpen ? 'fas fa-chevron-down' : 'fas fa-chevron-up'
          "
        ></i>
        <i @click="closeChatWindow" class="fas fa-times"></i>
      </button>
    </header>

    <!-- Chat Messages -->
    <main v-if="isChatWindowOpen" class="chat-messages" ref="messagesContainer">
      <div
        v-for="(message, index) in messages"
        :key="index"
        :class="[
          'chat-message',
          message.sender_name === `${receiver.first_name} ${receiver.last_name}`
            ? 'received'
            : 'sent',
        ]"
      >
        <p class="message-text">{{ message.message }}</p>
        <span class="message-info">
          {{ message.sender_name }} - {{ message.time }} {{ message.date }}
        </span>
      </div>
    </main>

    <!-- Chat Input -->
    <footer v-if="isChatWindowOpen" class="chat-input-section">
      <div class="textarea-container">
        <textarea
          v-model="messageInput"
          @keyup.enter="sendMessage"
          placeholder="Saisis ton message"
          aria-label="Write your message"
        ></textarea>
      </div>
      <div class="input-actions">
        <div class="input-icons">
          <button
            v-for="icon in inputIcons"
            :key="icon.alt"
            class="input-icon"
            @click="icon.action"
            :aria-label="icon.alt"
          >
            <img :src="icon.src" :alt="icon.alt" />
          </button>
        </div>
        <button
          class="send-button"
          @click="sendMessage"
          aria-label="Send Message"
        >
          <img
            src="@/assets/icons/icon-send-msg-primary1.svg"
            alt="Send Icon"
          />
        </button>
      </div>
    </footer>
  </section>
</template>
<script>
  import axios from 'axios';
  import { axiosInstance } from '../../services/axios';
  import { initializeChatWebSocket } from '../../utils/webSocketUtils';
  import UserAvatar from '@/components/views-models/profil/UserAvatar.vue';
  export default {
    props: {
      receiver: {
        type: Object,
        required: true,
      },
      isInLayout: {
        type: Boolean,
        required: false,
        default: false,
      },
      isOpen: {
        type: Boolean,
        required: false,
        default: true,
      },
      lastConversation: {
        type: Object,
        required: false, // Peut être null si aucune conversation n'est sélectionnée
      },
    },
    components: {
      UserAvatar,
    },
    data() {
      return {
        sender: this.$store.getters.getUser,
        isChatWindowOpen: this.isOpen,
        messages: [],
        chatMessages: {},
        unreadMessages: {},
        previousMessages: [],
        conversations: [],
        messageInput: '',
        currentId: null,
        recipientId: null,
        // Ici on peut passer les fonctions onClick pour les icons, dans la celé 'action'
        inputIcons: [
          {
            src: require('@/assets/text-black.svg'),
            alt: 'Text Icon',
            action: () => {},
          },
          {
            src: require('@/assets/more-black.svg'),
            alt: 'More Options',
            action: () => {},
          },
          {
            src: require('@/assets/at-black.svg'),
            alt: 'Mention Icon',
            action: () => {},
          },
          {
            src: require('@/assets/pj-black.svg'),
            alt: 'Attach Icon',
            action: () => {},
          },
          {
            src: require('@/assets/emoji-black.svg'),
            alt: 'Emoji Icon',
            action: () => {},
          },
        ],
      };
    },
    watch: {
      messages() {
        this.scrollToBottom();
      },
    },
    methods: {
      scrollToBottom() {
        this.$nextTick(() => {
          const container = this.$refs.messagesContainer;
          if (container) {
            container.scrollTop = container.scrollHeight;
          }
        });
      },
      onClick() {
        const id1 = this.sender.id;
        const idRecipient = this.receiver.id;
        console.log('currentId:', id1, 'recipientId:', idRecipient);
        if (id1 && idRecipient) {
          this.$router.push(
            idRecipient === id1
              ? `/utilisateur/${id1}`
              : `/utilisateur/${idRecipient}`
          );
        } else {
          console.error('Erreur : Les ID ne sont pas valides.');
        }
      },
      async fetchConversations(id1, id2) {
        try {
          const response = await axios.get(
            `https://websocket.thanks-boss.com/conversation/${id1}/${id2}/`
          );

          const newMessages = response.data.map((msg) => ({
            sender_name: `${msg.sender.first_name} ${msg.sender.last_name}`,
            message: msg.message,
            time: this.formatTime(msg.time),
            date: this.formatDate(msg.date),
            read: msg.read,
          }));
          const messagesHaveChanged = !this.areMessagesEqual(
            newMessages,
            this.messages
          );

          if (messagesHaveChanged) {
            this.messages = newMessages;
            // this.playNotificationSound();

            newMessages.forEach((message) => {
              if (!message.read && message.sender_name !== this.currentId) {
                this.unreadMessages[message.sender_name] = true;
                // this.markAsRead(message);
              }
            });
          }
        } catch (error) {
          console.error('Erreur lors de la récupération des messages :', error);
        }
      },
      areMessagesEqual(newMessages, oldMessages) {
        if (newMessages.length !== oldMessages.length) return false;

        for (let i = 0; i < newMessages.length; i++) {
          if (newMessages[i].message !== oldMessages[i].message) {
            return false;
          }
        }
        return true;
      },
      async markAsRead(message) {
        try {
          const response = await axiosInstance.post(
            `https://websocket.thanks-boss.com/read/${this.currentId}/${this.receiver}/`
          );
          console.log('reponse', response);

          const msg = this.messages.find((m) => m === message);
          if (msg) {
            msg.read = true;
            delete this.unreadMessages[message.sender_name];
          }
        } catch (error) {
          console.error(
            'Erreur lors de la mise à jour du statut du message :',
            error
          );
        }
      },
      initializeChatWebSocketConnection(senderId, receiverId) {
        this.fetchConversations(senderId, receiverId);
        const { webSocket } = initializeChatWebSocket(senderId, receiverId);
        this.chatSocket = webSocket;

        this.chatSocket.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (data.type === 'chat') {
            this.messages.push({
              sender_name: `${data.sender.first_name} ${data.sender.last_name}`,
              message: data.message,
              time: this.formatTime(new Date()),
            });
            if (data.sender.id !== this.currentId) {
              this.unreadMessages[data.sender_name] = true;
            }
          }
          this.fetchConversations(senderId, receiverId);
        };
      },
      formatTime(time) {
        if (time instanceof Date) {
          const hours = time.getHours().toString().padStart(2, '0');
          const minutes = time.getMinutes().toString().padStart(2, '0');
          return `${hours}:${minutes}`;
        } else if (typeof time === 'string') {
          const [hours, minutes] = time.split(':');
          return `${hours}:${minutes}`;
        }
        throw new Error('Invalid time format');
      },
      formatDate(date) {
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        const todayStr = today.toISOString().split('T')[0];
        /*  if (date === todayStr) {
          return "aujourd'hui";
        }*/
        const [year, month, day] = date.split('-');
        return `${day}-${month}-${year}`;
      },
      sendMessage() {
        if (this.chatSocket && this.chatSocket.readyState === WebSocket.OPEN) {
          const message = {
            sender_id: this.sender.id,
            message: this.messageInput.trim(),
          };
          this.chatSocket.send(JSON.stringify(message));

          const now = new Date();
          const formattedTime = `${now
            .getHours()
            .toString()
            .padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;

          this.messages.push({
            sender_name: this.sender.first_name,
            message: this.messageInput.trim(),
            time: this.formatTime(formattedTime),
          });
          this.messageInput = '';
          this.scrollToBottom();
        }
      },
      toggleChatOpen() {
        this.isChatWindowOpen = !this.isChatWindowOpen;
        this.$emit('toggleChatOpen', !this.isOpen);
      },
      closeChatWindow() {
        this.$emit('closeChatWindow', this.receiver.id);
      },
      startVideoCall() {
        this.$emit('start-video-call', this.receiver.id);
      },
    },
    mounted() {
      console.log(
        'Conversation reçue dans ChatWindow :',
        this.lastConversation
      );

      this.initializeChatWebSocketConnection(this.sender.id, this.receiver.id);
    },
    beforeDestroy() {
      if (this.chatSocket) {
        this.chatSocket.close();
      }
    },
  };
</script>
<style scoped>
  .chat-window {
    width: 500px;
    max-height: 650px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
  }

  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid #eee;
  }

  .chat-header-info {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .toggle-chat {
    display: flex;
  }

  .receiver-avatar {
    width: 50px;
    height: 50px;
    border-radius: 13%;
    object-fit: cover;
  }

  .receiver-name {
    font-weight: bold;
    font-size: 1rem;
    color: #333;
  }

  .chat-header-actions {
    display: flex;
    margin-left: 7rem;
  }
  .fa-chevron-down:before {
    font-size: 20px;
    padding: 10px;
  }
  .fa-times:before {
    font-size: 20px;
    padding: 10px;
  }
  .fa-chevron-up:before {
    font-size: 20px;
    padding: 10px;
  }

  .chat-action {
    background: none;
    border: none;
    padding: 5px;
    cursor: pointer;
  }

  .chat-messages {
    flex: 1;
    padding: 16px;
    overflow-y: auto;
    min-height: 400px;
    background-color: white;
    min-height: 359px;
    max-height: 359px;
  }

  .chat-message {
    padding: 15px;
    margin-bottom: 10px;
    max-width: 70%;
  }

  .sent {
    align-self: flex-end;
    justify-self: flex-end;
    background-color: var(--yellow-100);
    border-radius: 10px 10px 0px 10px;
  }

  .received {
    align-self: flex-start;
    background-color: #f1f1f1;
    border-radius: 10px 10px 10px 0px;
  }

  .message-text {
    font-size: 0.9rem;
    margin-bottom: 4px;
    font-weight: 600;
    word-wrap: break-word;
  }

  .message-info {
    font-size: 0.75rem;
    color: #777;
  }

  .chat-input-section {
    padding: 16px;
    border-top: 1px solid #eee;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .textarea-container textarea {
    width: 100%;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid var(--gray-300);
    background-color: #fdf5e6;
    font-size: 14px;
    resize: none;
    min-height: 80px;
  }

  .input-actions {
    display: flex;
    justify-content: space-between;
  }

  .input-icons {
    display: flex;
    gap: 8px;
  }

  .input-icon {
    background: none;
    border: none;
    cursor: pointer;
  }

  .send-button {
    border: none;
    padding: 8px 12px;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    .chat-window {
      width: 100%;
    }
    .chat-header-actions[data-v-c00ab182] {
      margin-left: 0rem;
    }
  }
</style>
