// Password rules
export const passwordRules = {
  minLength: 4,
  containsNumber: true,
  containsLowercase: true,
  containsUppercase: true,
  containsSpecialChar: true,
};

// Function to validate a password based on defined rules
export const validatePassword = (password) => {
  if (typeof password !== 'string') {
    return 'Le mot de passe est invalide.';
  }

  const {
    minLength,
    containsNumber,
    containsLowercase,
    containsUppercase,
    containsSpecialChar,
  } = passwordRules;

  switch (true) {
    case password.length < minLength:
      return `Le mot de passe doit comporter au moins ${minLength} caractères.`;
    case containsLowercase && !/[a-z]/.test(password):
      return 'Le mot de passe doit contenir au moins une lettre minuscule.';
    case containsNumber && !/\d/.test(password):
      return 'Le mot de passe doit contenir au moins un chiffre.';
    case containsUppercase && !/[A-Z]/.test(password):
      return 'Le mot de passe doit contenir au moins une lettre majuscule.';
    case containsSpecialChar && !/[!@#$%^&*(),.?":{}|<>]/.test(password):
      return 'Le mot de passe doit contenir au moins un caractère spécial.';
    default:
      return true;
  }
};

// Password confirmation rules including requirement to match the original password
export const passwordRules2 = {
  required: true,
  matchPassword: true,
};

// Function to validate the password confirmation
export const validatePassword2 = (password, confirmPassword) => {
  if (!confirmPassword) {
    return 'Le champ de confirmation du mot de passe est requis.';
  }
  if (password !== confirmPassword) {
    return 'Les mots de passe ne correspondent pas.';
  }
  return true;
};

// Email rules including length constraints, no spaces, and valid format
export const emailRules = {
  minLength: 5,
  maxLength: 50,
  noSpaces: true,
  validFormat: true,
};

// Function to validate an email based on defined rules
export const validateEmail = (email) => {
  if (typeof email !== 'string') {
    return "L'adresse e-mail est invalide.";
  }

  const { minLength, maxLength, noSpaces, validFormat } = emailRules;

  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  switch (true) {
    case email.length < minLength:
      return `L'adresse e-mail doit comporter au moins ${minLength} caractères.`;
    case email.length > maxLength:
      return `L'adresse e-mail ne doit pas dépasser ${maxLength} caractères.`;
    case noSpaces && /\s/.test(email):
      return "L'adresse e-mail ne doit pas contenir d'espace.";
    case validFormat && !emailRegex.test(email):
      return "L'adresse e-mail doit être valide.";
    default:
      return true;
  }
};

// Name rules including length constraints
export const nameRules = {
  minLength: 2,
  maxLength: 50,
};

// Function to validate a name based on defined rules
export const validateName = (name) => {
  if (typeof name !== 'string') {
    return 'Le nom est invalide.';
  }

  const { minLength, maxLength } = nameRules;

  switch (true) {
    case name.length < minLength:
      return `Le nom doit comporter au moins ${minLength} caractères.`;
    case name.length > maxLength:
      return `Le nom ne doit pas dépasser ${maxLength} caractères.`;
    default:
      return true;
  }
};

// Rule for non-empty fields
export const notEmptyRules = {
  required: true,
};

// Function to validate that a field is not empty
export const validateNotEmpty = (value) => {
  if (typeof value !== 'string') {
    return 'La valeur est invalide.';
  }

  return value.trim() !== '' || 'Le champ est requis';
};

export const validateMobile = (mobile) => {
  if (typeof mobile !== 'string') {
    return 'Le numéro est invalide.';
  }

  const cleanedMobile = mobile.replace(/\s+/g, '');

  const mobileRules = [
    (v) => !!v || 'Le champ est requis',
    (v) => v.length >= 10 || 'Le numéro doit comporter au moins 10 chiffres',
    (v) =>
      v.length <= 10 || 'Le numéro ne doit pas comporter plus de 10 chiffres',
    (v) => /^[0-9]+$/.test(v) || 'Le numéro doit comporter que des chiffres',
  ];

  for (const rule of mobileRules) {
    const result = rule(cleanedMobile);
    if (result !== true) {
      return result;
    }
  }

  return true;
};

export const validateSiret = (input) => {
  // Vérifier que l'entrée est bien une chaîne de caractères représentant un nombre
  if (typeof input !== 'string' || isNaN(Number(input))) {
    return "L'entrée doit être un numéro de SIRET valide (14 chiffres).";
  }

  const siretRules = [
    (v) => !!v || 'Le champ est requis',
    (v) =>
      /^\d{14}$/.test(v) ||
      'Le numéro de SIRET doit comporter exactement 14 chiffres',
  ];

  for (const rule of siretRules) {
    const result = rule(input);
    if (result !== true) {
      return result;
    }
  }

  return true;
};

// Function to validate a website URL based on defined rules
export const validateWebsite = (website) => {
  // Allow empty value
  if (typeof website !== 'string') {
    // Si le champ est vide ou undefined, cela est valide
    if (website === undefined || website === null || website.trim() === '') {
      return true;
    }
    return "L'URL du site est invalide.";
  }

  // Allow empty value
  if (website.trim() === '') {
    return true;
  }

  const websiteRules = [
    (v) => !/[%#$><*]/.test(v) || 'Ne doit pas contenir de caractères spéciaux',
    (v) =>
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(
        v
      ) || "L'adresse du site doit être valide",
  ];

  for (const rule of websiteRules) {
    const result = rule(website);
    if (result !== true) {
      return result;
    }
  }

  return true;
};

export const validateNoSpecialChar = (input) => {
  if (typeof input !== 'string') {
    return "L'entrée doit être une chaîne de caractères.";
  }

  const validationRules = [
    (v) => !!v || 'Le champ est requis',
    (v) =>
      !/[^a-zA-Z0-9]/.test(v) ||
      'Les caractères spéciaux ne sont pas autorisés',
  ];

  for (const rule of validationRules) {
    const result = rule(input);
    if (result !== true) {
      return result;
    }
  }

  return true;
};

export const validateStringRules = (input) => {
  if (typeof input !== 'string') {
    return "L'entrée doit être une chaîne de caractères.";
  }

  const stringRules = [
    (v) => !!v || 'Le champ est requis',
    (v) => v.length >= 2 || 'Le nom doit comporter au moins 2 caractères',
    (v) => v.length <= 50 || 'Le nom ne doit pas dépasser 50 caractères',
    (v) => !/[%#$><*]/.test(v) || 'Ne doit pas contenir de caractères spéciaux',
  ];

  for (const rule of stringRules) {
    const result = rule(input);
    if (result !== true) {
      return result;
    }
  }

  return true;
};

export const validateSalaryRules = (input) => {
  if (typeof input !== 'string') {
    return "L'entrée doit être une chaîne de caractères.";
  }

  const salaryRules = [
    (v) => !!v || 'Le champ est requis',
    (v) => v.length >= 4 || 'Le montant doit comporter au moins 4 chiffres',
    (v) => v.length <= 6 || 'Le montant ne doit pas dépasser 6 chiffres',
    (v) => /^[0-9]+$/.test(v) || 'Le numéro doit comporter que des chiffres',
    (v) => !/[%#$><*]/.test(v) || 'Ne doit pas contenir de caractères spéciaux',
  ];

  for (const rule of salaryRules) {
    const result = rule(input);
    if (result !== true) {
      return result;
    }
  }

  return true;
};

export const validateJobTitleRules = (input) => {
  if (typeof input !== 'string') {
    return "L'entrée doit être une chaîne de caractères.";
  }

  const jobTitleRules = [
    (v) => !!v || 'Le champ est requis',
    (v) => v.length >= 2 || 'Le nom doit comporter au moins 2 caractères',
    (v) => v.length <= 50 || 'Le nom ne doit pas dépasser 50 caractères',
    (v) => !/[%#$><*]/.test(v) || 'Ne doit pas contenir de caractères spéciaux',
  ];

  for (const rule of jobTitleRules) {
    const result = rule(input);
    if (result !== true) {
      return result;
    }
  }

  return true;
};

export const validateCvRules = (input) => {
  if (typeof input !== 'string') {
    return "L'entrée doit être une chaîne de caractères.";
  }

  const cvRules = [
    (v) => !!v || 'Le champ est requis',
    (v) => v.length >= 1 || 'Le nom doit comporter au moins 1 caractère',
    (v) => v.length <= 50 || 'Le nom ne doit pas dépasser 50 caractères',
    (v) => !/[%#$><*]/.test(v) || 'Ne doit pas contenir de caractères spéciaux',
  ];

  for (const rule of cvRules) {
    const result = rule(input);
    if (result !== true) {
      return result;
    }
  }

  return true;
};
export const validateLargeStringRules = (input) => {
  if (typeof input !== 'string') {
    return "L'entrée doit être une chaîne de caractères.";
  }

  const largeStringRules = [
    (v) => !!v || 'Le champ est requis',
    (v) => v.length >= 2 || 'Le nom doit comporter au moins 2 caractères',
    (v) => v.length <= 1000 || 'Le nom ne doit pas dépasser 1000 caractères',
    (v) => !/[%#$><*]/.test(v) || 'Ne doit pas contenir de caractères spéciaux',
  ];

  for (const rule of largeStringRules) {
    const result = rule(input);
    if (result !== true) {
      return result;
    }
  }

  return true;
};

export const validateDate = (input) => {
  // Vérifier que l'entrée est bien une chaîne de caractères représentant un nombre
  if (typeof input !== 'string' || isNaN(Number(input))) {
    return "L'entrée doit être une année valide (4 chiffres).";
  }

  const currentYear = new Date().getFullYear();

  const dateRules = [
    (v) => !!v || 'Le champ est requis',
    (v) => /^\d{4}$/.test(v) || "L'année doit comporter exactement 4 chiffres",
    (v) =>
      parseInt(v, 10) <= currentYear ||
      `L'année ne doit pas dépasser ${currentYear}`,
    (v) => parseInt(v, 10) >= 1900 || "L'année doit être postérieure à 1900",
  ];

  for (const rule of dateRules) {
    const result = rule(input);
    if (result !== true) {
      return result;
    }
  }

  return true;
};

export default {
  validatePassword,
  validateEmail,
  validateNotEmpty,
  validatePassword2,
  validateMobile,
  validateWebsite,
  validateNoSpecialChar,
  validateStringRules,
  validateSalaryRules,
  validateJobTitleRules,
  validateCvRules,
  validateLargeStringRules,
  validateDate,
};
